var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});
var detectTouchEvents = {
  update: function update() {
    if (typeof window !== "undefined") {
      detectTouchEvents.hasSupport = "ontouchstart" in window;
      detectTouchEvents.browserSupportsApi = Boolean(window.TouchEvent);
    }
  }
};
detectTouchEvents.update();
exports.default = detectTouchEvents;
export default exports;
export const __esModule = exports.__esModule;